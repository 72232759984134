<template lang="pug">
    article#home
        Frases
        Pessoa
        Cidade
        AGt
</template>

<script>
import Cidade from '@/components/Cidade/Cidade.vue'
import Frases from '@/components/Frases/Frases.vue'
import Pessoa from '@/components/Pessoa/Pessoa.vue'
import AGt from '@/sections/Home/AGt/AGt.vue'

export default {
    components: {
        Cidade,
        Frases,
        Pessoa,
        AGt
    },
    methods: {
    },
}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>