<template lang="pug">
    ul#frases
        li(v-for="frase in frases", ref="frases") {{ frase.frase }}
</template>

<script>
export default {
    data() {
        return {
            frases: [
                {
                    frase: 'Negócios Inteligentes',
                    positionY: 150,
                    velocidade: .8
                },
                {
                    frase: 'Energia',
                    positionY: 100,
                    velocidade: .95
                },
                {
                    frase: 'Que Transformam Vidas',
                    positionY: 375,
                    velocidade: .9
                },
                {
                    frase: 'Consultoria',
                    positionY: 600,
                    velocidade: .8
                },
                {
                    frase: 'Educação',
                    positionY: 500,
                    velocidade: .98
                },
                {
                    frase: 'Mudança de Vida',
                    positionY: 750,
                    velocidade: .8
                },
            ],
            scrollElement: null
        }
    },
    methods: {
        doScroll() {                
            this.$refs.frases.forEach((frase, index) => {
                const position = this.frases[index].positionY;
                const velocidade = this.frases[index].velocidade;

                if (position > this.scrollElement.innerHeight)
                    frase.style.top = position + (100 * (index * .75)) - this.scrollElement.scrollTop * velocidade + 'px';
                else
                    frase.style.top = position - this.scrollElement.scrollTop * velocidade + 'px';
            })
        }
    },
    created() {
        // this.scrollElement = document.querySelector(".home");
        // this.scrollElement.addEventListener('scroll', this.doScroll)
    }, 
    mounted() {
        this.$refs.frases.forEach((frase, index) => {
            frase.style.top = this.frases[index].positionY + 'px';
        })
    },
}
</script>

<style lang="stylus" scoped src="./Frases.styl"></style>