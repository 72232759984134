<template lang="pug">
    section#AGt
        ul
            li.sobre
                router-link(:to="{name:'sobre'}")
                    figure
                        img(:src="require('@/assets/images/home/sobre.jpg')", alt="A GT Business")
                        figcaption
                            | Sobre
            li.yachthouse
                router-link(:to="{name:'yachthouse'}")
                    figure
                        img(:src="require('@/assets/images/home/yachthouse.jpg')", alt="O Yachthouse")
                        figcaption
                            | O
                            br
                            | Yachthouse
            li.partners
                router-link(:to="{name:'partners'}")
                    figure
                        img(:src="require('@/assets/images/home/partners.jpg')", alt="A GT Partners")
                        figcaption
                            | GT.
                            br
                            | Partners
            li.business
                router-link(:to="{name:'business'}")
                    figure
                        img(:src="require('@/assets/images/home/business.jpg')", alt="A GT Business")
                        figcaption
                            | GT.
                            br
                            | Business
            
</template>

<script>
export default {
    
}
</script>

<style lang="stylus" scoped src="./AGt.styl"></style>